import React from "react";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Seo from "../../../components/Seo";
import Layout from "../../../layouts";
import Banner from "../../../images/services/qa/qa-test-cover.png";
import ContrastSection from "../../../components/ContrastSection";
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import HelpSection from "../../../components/Help";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
} from "reactstrap";

import iconBalance from "../../../images/services/qa/icon/balance.svg";
import iconCost from "../../../images/services/qa/icon/costeffective.svg";
import iconProfessional from "../../../images/services/qa/icon/professional.svg";
import iconRisk from "../../../images/services/qa/icon/risksystem.svg";

import iconExperience from "../../../images/services/qa/icon/experience.svg";
import iconCompetency from "../../../images/services/qa/icon/competencies.svg";
import iconTesting from "../../../images/services/qa/icon/testing.svg";

import iconCertified from "../../../images/services/qa/icon/certified.svg";
import iconGdpr from "../../../images/services/qa/icon/gdpr.svg";
import iconTools from "../../../images/services/qa/icon/build.svg";

import Chanakya from "../../../images/services/qa/chanakya.png";
import Sylvia from "../../../images/services/qa/sylvia.jpeg";

import iconAnalysis from "../../../images/process-analysis.svg";
import Whodepemdonus from "../../../components/WhoDependsOnUs";
import UserCard from "../../../components/UserCard";

import imgQA from "../../../images/services/qa/application.png";
import imgValidation from "../../../images/services/qa/qa-verification.png";
import imgApplication from "../../../images/services/qa/application2.jpg";
import imgTeam from "../../../images/services/qa/team.png";

import logoISTQB from "../../../images/services/qa/istqb-platinum.png";

const servicesList = [
  {
    id: "1",
    title: "Product Quality Engineering",
    textbold: "Developing a new product that needs to be independently tested?",
    textdetail:
      "If you are developing a tech product that needs independent, comprehensive testing, iTelaSoft’s Product Quality Engineering service provides end to end coverage. Starting with quality assessment, then quality approach, test design & execution to issue mediation and resolution, we support you with a complete product quality management framework.",
  },
  {
    id: "2",
    title: "Platform Validation",
    textbold: "Need to validate your existing software platform? ",
    textdetail:
      "A software platform is a live entity. Based on changes they go through, information they accumulate, people who access them, and vulnerabilities emerging, they evolve constantly. Corporates need to validate an existing platform from time to time to ensure it serves the right environment in the right way. iTelaSoft’s Platform Validation service offers an end-to-end assessment on usability, security, performance, vulnerabilities, and compliance.",
  },
  {
    id: "3",
    title: "Product Modernisation Verification",
    textbold: "Planning to modernise your legacy application? ",
    textdetail:
      "Inevitably, software products also come to an end of life eventually; and may get modernised as a new product. Modernisation engagements are usually complex due to new tech, new frameworks, added capabilities and changed user experience, plus backward compatibility, existing integrations, and data migrations. We provide Product Modernisation Verification service, that starts with integrated planning, verification strategy and testing.",
  },
  {
    id: "4",
    title: "Managed Test Teams",
    textbold: "Need qualified quality engineers to extend your team?",
    textdetail:
      "Many organisations, especially medium to large scale enterprises often have their own product development team. In some cases, they have quality assurance and testing professionals as well. Despite in-house capacity, there are many reasons where external support is required. Those include, additional capacity needs, tooling and domain expertise, automation, accelerating time to market, and cost reduction. iTelaSoft provide Managed Test Teams to extend your team and perform software testing faster, better.",
  },
];

const qa = () => {
  return (
    <Layout bodyClass="service-page">
      <Seo
        title="Independent Validation & Quality Assurance"
        description="iTelaSoft is an Australian-owned company providing IT services and
              software product development.. We specialise in strategy
              consultation, design, development and management of products. We
              help entrepreneurs transform ground breaking ideas into successful
              solutions."
      />

      <IntroBanner
        title="Independent Validation & Quality Assurance"
        subtitle="Ensure your product is fit for purpose and comply with user expectations"
        image={Banner}
      />

      <div className="content">
        <ContrastSection
          title="The Power of Quality Engineering with iTelaSoft"
          para="Quality Engineering and Validation are crucial for the success of any software platform or tech product. These practices ensure customer satisfaction, maintain brand reputation, achieve cost savings, comply with regulations, and gain a competitive advantage. Whether you have an existing software platform that needs improvement or starting to build a new one from scratch, iTelaSoft can provide the necessary support and expertise for your software testing needs."
          para2="Our team can help you modernise your existing platform or build a new tech product, ensuring that it meets the highest quality standards and exceeds customer expectations. By partnering with iTelaSoft, you can be confident in the reliability and performance of your software solution."
        />
      </div>
      <section className="section-our-partners">
        <Container className="industry-section">
          <Row className="mt-4 industry-partners">
            <Col>
              <div className="container pb-6 section-2">
                <div className="row">
                  <div className="col-md-6 pr-md-5">
                    <img
                      alt="istqb logo"
                      className="img-fluid"
                      src={logoISTQB}
                    />
                  </div>
                  <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-end">
                    {/* <div className="title title-1 mb-2">Industry Partners</div> */}
                    <p>
                      ISTQB is the International Software Testing Qualifications Board that set qualification standards and testing of professionals. This accreditation ensures correct capabilities and trust to our customers in quality engineering needs.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="mt-5">
          <div className="title title-1 mb-2">Why an Independent Partner is Important?</div>
          <div className="sub-title">
            Choosing an independent quality engineering and validation partner
            such as iTelaSoft offers several advantages.{" "}
          </div>

          <Row className="mt-4">
            <Col md={6} lg={""}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconBalance}
                  />
                  <CardTitle tag="h5">
                    Unbiased and independent assessment{" "}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconProfessional}
                  />
                  <CardTitle tag="h5">
                    Access to specialised and experienced professionals with
                    modern tooling & frameworks
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={""}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconCost}
                  />
                  <CardTitle tag="h5">
                    Flexibility, scalability & cost effectiveness
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={""}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconRisk}
                  />
                  <CardTitle tag="h5">
                    Risk mitigation and regulatory compliance
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="service-container">
          <Row>
            <Col>
              <div>
                <div className="title title-1 mb-2">Services</div>
                <div className="sub-title">
                iTelaSoft provides a wide range of Quality Engineering Services for different scenarios.{" "}
                </div>
              </div>
              <div className="mt-4">

                <div className="container section-2">
                  <div className="row">
                    <div className="col-md-6 pr-md-5">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={imgQA}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Product Quality Engineering
                      </h2>
                      <p>
                        <strong>
                          Developing a new product that needs to be independently tested?
                        </strong>
                        <br />
                          A software platform is a live entity. Based on changes they go through, information they accumulate, people who access them, and vulnerabilities emerging, they evolve constantly. Corporates need to validate an existing platform from time to time to ensure it serves the right environment in the right way. iTelaSoft’s Platform Validation service offers an end-to-end assessment on usability, security, performance, vulnerabilities, and compliance. 
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5 order-md-2">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={imgApplication}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Platform Validation
                      </h2>
                      <p>
                        <strong>
                          Need to validate your existing software platform?{" "}
                        </strong>
                        <br />
                        We'll work with you to create a high-quality website that matches
                        your needs, ensures ease of maintenance and maximises cost
                        efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={imgValidation}
                      />
                    </div>

                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">Product Modernisation Verification</h2>
                      <p>
                        <strong>Planning to modernise your legacy application?</strong>
                        <br />
                          Inevitably, software products also come to an end of life eventually; and may get modernised as a new product. Modernisation engagements are usually complex due to new tech, new frameworks, added capabilities and changed user experience, plus backward compatibility, existing integrations, and data migrations. We provide Product Modernisation Verification service, that starts with integrated planning, verification strategy and testing.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5 order-md-2">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={imgTeam}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Managed Test Teams
                      </h2>
                      <p>
                        <strong>
                          Need qualified quality engineers to extend your team?{" "}
                        </strong>
                        <br />
                          Many organisations, especially medium to large scale enterprises often have their own product development team. In some cases, they have quality assurance and testing professionals as well. Despite in-house capacity, there are many reasons where external support is required. Those include, additional capacity needs, tooling and domain expertise, automation, accelerating time to market, and cost reduction. iTelaSoft provide Managed Test Teams to extend your team and perform software testing faster, better.
                      </p>
                    </div>
                  </div>
                </div>

                {/* {servicesList.map((service) => {
                  return (
                    <div className="wrapper-service" key={service.id}>
                      <div className="service-details">
                        <div className="service-title">{service.title}</div>
                        <div className="service-content">
                          <div className="service-breif">
                            {service.textbold}
                          </div>
                          <div className="service-description">
                            {service.textdetail}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })} */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <div>
            <div className="title title-1 mb-2">Why iTelaSoft?</div>
            <div className="sub-title">
              iTelaSoft is a full spectrum software services provider with its
              independent Quality Engineering and Validation team. There are many
              reasons why we have been a trusted testing provider for more than 10
              years
            </div>
          </div>
          <Row className="mt-4">
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconExperience}
                  />
                  <CardTitle tag="h5">
                    Experience in complex applications and multiple domains
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconCompetency}
                  />
                  <CardTitle tag="h5">
                    Test competency in web, mobile, API, IoT, and integration
                    applications.
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconTesting}
                  />
                  <CardTitle tag="h5">
                    Specialised test needs such as cellular service testing &
                    salesforce testing.
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconTools}
                  />
                  <CardTitle tag="h5">
                    Mature methodology and tool-set.
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconCertified}
                  />
                  <CardTitle tag="h5">
                    ISTQB Platinum Partner with certified engineers
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconGdpr}
                  />
                  <CardTitle tag="h5">
                    Compliance with industry standards (GDPR)
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-our-partners">
        <Container>
          <Row>
            <Col>
              <Whodepemdonus />
            </Col>
          </Row>
        </Container>
      </section>
      
      <section>
        <div className="content">
          <HelpSection
            title="Let Us Know How to Help"
            para="We love to hear what challenges you go through in your business and discuss how we can assist you."
          />
        </div>
      </section>
      <section className="section-contact">
        <Container>
          <Row>
                <Col md={12} lg={6} xl={6} className="title-section">
                  <div className="title title-1 mb-2 desk">
                    Contact to <br/>
                    Discover More 
                  </div>
                  <div className="title title-1 mb-2 mobile">
                    Contact to Discover More 
                  </div>
                </Col>
                <Col md={6} lg={3} xl={3} className="mb-2">
                    <a className="contact-card" href="/contact/">
                      <UserCard
                        name="Chanakya Dharmasiri"
                        detail="Quality Adviser"
                        image={Chanakya}
                        link="https://www.linkedin.com/in/chanakya-dharmasiri/"
                      />
                    </a>
                </Col>
                <Col md={6} lg={3} xl={3} className="mb-2">
                    <a className="contact-card" href="/contact/">
                      <UserCard
                        name="Sylvia Luo"
                        detail="Relationship Manager"
                        image={Sylvia}
                        link="https://www.linkedin.com/in/sylvia-luo-lol980609/"
                      />
                    </a>
                </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default qa;
